import { ProjectAction, ProjectTask } from '../../../apiHooks/useProjects';

export enum ProjectStageStatus {
  NotStarted,
  InProgress,
  Completed,
}

export type ProjectStage = {
  id: number;
  name: string;
  description: string | null;
  actions: ProjectAction[];
  tasks: ProjectTask[];
  status: ProjectStageStatus;
};
