import React, { useMemo } from 'react';
import { Container, List, ListTitle } from './styled';
import { ActionStatus } from '../../../../gql/graphql';
import { Action } from '../../../../features/Action';
import { ProjectAction } from '../../../../apiHooks/useProjects';
import { TaskVisit, Visit } from '../../Visit';
interface Props {
  visits: (TaskVisit & { order: number; totalVisits: number })[];
  actions: ProjectAction[];
}

export const Events = ({ visits, actions }: Props) => {
  const sortedVisits = useMemo(() => {
    const result = [...visits];

    result.sort((a, b) => {
      const aDate = a.startDate;
      const bDate = b.startDate;

      const aCompleted = a.isCompleted;
      const bCompleted = b.isCompleted;

      if (aCompleted && bCompleted) {
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      if (aCompleted) {
        return 1;
      }

      if (bCompleted) {
        return -1;
      }

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    return result;
  }, [visits]);

  const sortedActions = useMemo(() => {
    const result = [...actions];

    result.sort((a, b) => {
      const aDate = a.dueDate ?? a.createdAt;
      const bDate = b.dueDate ?? b.createdAt;

      const aCompleted = a.status === ActionStatus.Completed;
      const bCompleted = b.status === ActionStatus.Completed;

      const aReview = a.status === ActionStatus.InReview;
      const bReview = b.status === ActionStatus.InReview;

      if ((aReview && bReview) || (aCompleted && bCompleted)) {
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      if (aCompleted) {
        return 1;
      }

      if (bCompleted) {
        return -1;
      }

      if (aReview) {
        return 1;
      }

      if (bReview) {
        return -1;
      }

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    return result;
  }, [actions]);

  if (actions.length === 0 && visits.length === 0) {
    return null;
  }

  return (
    <Container>
      {sortedActions.length > 0 && (
        <div>
          <ListTitle>Your Tasks ({sortedActions.length})</ListTitle>
          <List>
            {sortedActions.map((action) => (
              <Action key={action.id} action={action} />
            ))}
          </List>
        </div>
      )}

      {sortedVisits.length > 0 && (
        <div>
          <ListTitle>Visits ({sortedVisits.length})</ListTitle>
          <List>
            {sortedVisits.map((visit) => (
              <Visit
                key={visit.id}
                visit={visit}
                order={visit.totalVisits > 1 ? visit.order : undefined}
              />
            ))}
          </List>
        </div>
      )}
    </Container>
  );
};
