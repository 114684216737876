import React, { useMemo } from 'react';
import { useRequests } from '../../../apiHooks/useRequests';
import { Request } from '../../../kit/components/Request';

export const ActiveRequests = () => {
  const { data: requests = [] } = useRequests();

  const activeRequests = useMemo(
    () =>
      requests.filter(
        (request) =>
          request.isActive &&
          !['WON', 'LOST'].includes(request.stage?.type ?? ''),
      ),
    [requests],
  );

  if (activeRequests.length === 0) {
    return null;
  }

  return (
    <>
      {activeRequests.map((request) => (
        <Request key={request.id} request={request} />
      ))}
    </>
  );
};
