import { ActionStatus, TaskStatus } from '../../../gql/graphql';
import { ProjectStage, ProjectStageStatus } from './types';

export const getProjectStageStatus = ({
  stage,
  currentStageId,
  allStages,
}: {
  stage: Omit<ProjectStage, 'status'>;
  allStages: Omit<ProjectStage, 'status'>[];
  currentStageId: number | null;
}): ProjectStageStatus => {
  const currentStageIndex = allStages.findIndex(
    (stage) => stage.id === currentStageId,
  );

  const stageIndex = allStages.findIndex((s) => s.id === stage.id);

  const isLast = allStages[allStages.length - 1].id === stage.id;
  const isFuture = stageIndex > currentStageIndex;
  const isCurrent = stage.id === currentStageId;

  const notCompletedHomeOwnerTasks = stage.actions.filter(
    (action) =>
      action.status !== ActionStatus.Completed &&
      action.status !== ActionStatus.Cancelled,
  );

  const notCompletedTasks = stage.tasks.filter(
    (task) =>
      task &&
      task.status !== TaskStatus.Completed &&
      task.status !== TaskStatus.Cancelled,
  );

  if (notCompletedHomeOwnerTasks.length > 0 || notCompletedTasks.length > 0) {
    if (isFuture) {
      const isAllQueued = stage.tasks.every(
        (task) => task && task.status === TaskStatus.Queued,
      );

      return isAllQueued
        ? ProjectStageStatus.NotStarted
        : ProjectStageStatus.InProgress;
    }
    return ProjectStageStatus.InProgress;
  }

  if (isCurrent) {
    if (
      isLast &&
      notCompletedHomeOwnerTasks.length === 0 &&
      notCompletedTasks.length === 0
    ) {
      return ProjectStageStatus.Completed;
    }

    return ProjectStageStatus.InProgress;
  }

  if (isFuture) {
    return ProjectStageStatus.NotStarted;
  }

  return ProjectStageStatus.Completed;
};
