import React, { useCallback, useEffect, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  Container,
  Nav,
  LogoImage,
  LogoName,
  NavItem,
  UserMenuItem,
  AvatarPlaceholder,
  Content,
  ChatContainer,
  StickyChat,
  UserDropdownMenu,
  UserDropdownMenuItem,
  MobileNav,
  CloseMenu,
  MobileMenuItem,
  MobileChatButton,
  MobileMenuHeader,
  MobileSeparator,
  AvatarAndName,
  MenuRight,
  NavCenter,
  NavLeft,
  MobileMenuButton,
} from './styled';
import { useMe } from '../../apiHooks/useMe';
import { ChevronDownIcon } from '../../kit/ui/icons/ChevronDown';
import { useCompany } from '../../apiHooks/useCompany';
import { Chat } from '../../features/Chat';
import { LogOutIcon } from '../../kit/ui/icons/LogOut';
import { sessionService } from '../../services/SessionService';
import { MenuIcon } from '../../kit/ui/icons/Menu';
import { XIcon } from '../../kit/ui/icons/X';
import { MessageIcon } from '../../kit/ui/icons/Message';
import { Button, ButtonVariant } from '../../kit/ui/Button';
import { PresentIcon } from '../../kit/ui/icons/Present';
import { useModal } from '../../kit/components/PromiseModal';
import { Referral } from '../../features/Referral';
import { Popover } from '../../kit/ui/Popover';
import { RequestForm } from '../../features/RequestForm';
import { useRequestProperties } from '../../apiHooks/useRequestProperties';

export const MainLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth >= 768);
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');
  const { data: currentUser } = useMe();
  const { openModal } = useModal();

  // preload request properties
  useRequestProperties();

  const referralSettings = company?.settings?.referral;

  const isReferralEnabled = referralSettings?.enabled;

  const isRequestButtonShown = company?.settings?.requestButtonEnabled;

  const navigate = useNavigate();
  const location = useLocation();

  const userInitials =
    currentUser?.me?.name
      .split(' ')
      .map((part) => part[0])
      .join('') ?? '';

  const handleLogout = useCallback(() => {
    sessionService.logout(workspaceSlug!);
    navigate(`/${workspaceSlug}/auth/login`);
  }, [navigate, workspaceSlug]);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(window.innerWidth >= 768);
  }, [location.pathname]);

  const handleReferClick = useCallback(() => {
    if (!referralSettings) {
      return;
    }

    openModal<void>(
      ({ onClose }) => <Referral {...referralSettings} onClose={onClose} />,
      {
        title: referralSettings?.promptTitle,
        maxWidth: '90vw',
        isMobileFullScreen: true,
      },
    );
  }, [openModal, referralSettings]);

  const handleSubmitRequestClick = useCallback(() => {
    openModal<void>(({ onClose }) => <RequestForm onClose={onClose} />, {
      title: 'Submit Request',
    });
  }, [openModal]);

  return (
    <Container>
      <MobileNav>
        <MenuIcon size="24px" color="#9C9CAA" onClick={toggleMenu} />

        <div>
          {company?.logoUrl && <LogoImage src={company.logoUrl} />}

          {!company?.logoUrl && <LogoName>{company?.name}</LogoName>}

          {isRequestButtonShown && (
            <Button
              onClick={handleSubmitRequestClick}
              variant={ButtonVariant.Primary}
            >
              Submit request
            </Button>
          )}

          <MobileChatButton to="chat">
            <MessageIcon size="20px" />
          </MobileChatButton>
        </div>
      </MobileNav>
      {isMenuOpen && (
        <Nav>
          <MobileMenuHeader>
            <CloseMenu onClick={toggleMenu}>
              <XIcon size="24px" />
            </CloseMenu>

            {company?.logoUrl && <LogoImage src={company.logoUrl} />}

            {!company?.logoUrl && <LogoName>{company?.name}</LogoName>}
          </MobileMenuHeader>

          <NavLeft>
            {company?.logoUrl && <LogoImage src={company.logoUrl} />}

            {!company?.logoUrl && <LogoName>{company?.name}</LogoName>}

            <NavCenter>
              <Link to="">
                <NavItem
                  isActive={window.location.pathname === `/${workspaceSlug}`}
                >
                  Home
                </NavItem>
              </Link>
              <Link to="requests">
                <NavItem
                  isActive={
                    window.location.pathname === `/${workspaceSlug}/requests`
                  }
                >
                  Requests
                </NavItem>
              </Link>
              <Link to="projects-and-services">
                <NavItem
                  isActive={
                    window.location.pathname ===
                    `/${workspaceSlug}/projects-and-services`
                  }
                >
                  Projects & Services
                </NavItem>
              </Link>
              <Link to="docs">
                <NavItem
                  isActive={
                    window.location.pathname === `/${workspaceSlug}/docs`
                  }
                >
                  Docs
                </NavItem>
              </Link>
            </NavCenter>
          </NavLeft>

          <MenuRight>
            {isReferralEnabled && (
              <>
                <div className="mobile">
                  <MobileMenuButton onClick={handleReferClick}>
                    <PresentIcon size="16px" color="#009688" />
                    Refer & Earn
                  </MobileMenuButton>
                </div>
                <Button
                  onClick={handleReferClick}
                  className="desktop"
                  variant={ButtonVariant.Flat}
                >
                  <PresentIcon size="16px" />
                  Refer & Earn
                </Button>
              </>
            )}
            {isRequestButtonShown && (
              <Button
                onClick={handleSubmitRequestClick}
                variant={ButtonVariant.Primary}
              >
                Submit request
              </Button>
            )}
            <Popover
              content={
                <UserDropdownMenu>
                  <UserDropdownMenuItem onClick={handleLogout}>
                    <LogOutIcon size="16px" />
                    Log out
                  </UserDropdownMenuItem>
                </UserDropdownMenu>
              }
            >
              <UserMenuItem>
                <AvatarPlaceholder>{userInitials}</AvatarPlaceholder>
                <ChevronDownIcon size="16px" />
              </UserMenuItem>
            </Popover>
          </MenuRight>

          <MobileSeparator />

          <MobileMenuItem>
            <AvatarAndName>
              <AvatarPlaceholder>{userInitials}</AvatarPlaceholder>

              {currentUser?.me?.name}
            </AvatarAndName>

            <MobileChatButton to="chat">
              <MessageIcon size="20px" />
            </MobileChatButton>
          </MobileMenuItem>

          <MobileMenuItem onClick={handleLogout}>
            Log out
            <LogOutIcon size="16px" />
          </MobileMenuItem>
        </Nav>
      )}

      <Content>
        <Outlet />

        <ChatContainer>
          <StickyChat>
            <Chat />
          </StickyChat>
        </ChatContainer>
      </Content>
    </Container>
  );
};
