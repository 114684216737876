import React, { useCallback, useMemo, useState } from 'react';
import { Container } from './styled';
import { Stage } from './Stage';
import { ProjectStage, ProjectStageStatus } from '../types';

interface Props {
  stages: ProjectStage[];
  currentStageId: number | null;
}

export const Stages = ({ currentStageId, stages }: Props) => {
  const currentStageIndex = useMemo(
    () => stages.findIndex((stage) => stage.id === currentStageId),
    [stages, currentStageId],
  );

  const [expandedStageId, setExpandedStageId] = useState<number | null>(
    stages[currentStageIndex].status !== ProjectStageStatus.Completed
      ? currentStageId
      : null,
  );

  const handleStageClick = useCallback((id: number) => {
    setExpandedStageId((prev) => (prev === id ? null : id));
  }, []);

  return (
    <Container>
      {stages.map((stage, index) => (
        <Stage
          key={stage.id}
          isExpanded={expandedStageId === stage.id}
          onClick={handleStageClick}
          stage={stage}
          index={index}
          isFuture={index > currentStageIndex}
        />
      ))}
    </Container>
  );
};
