import React, { useCallback, useMemo } from 'react';
import {
  ActionRequired,
  StageContainer,
  StageContent,
  StageDescription,
  StageHeader,
  StageName,
} from './styled';
import { CheckCircleIcon } from '../../../ui/icons/CheckCircle';
import { Badge } from '../../../ui/Badge';
import { ProjectStage, ProjectStageStatus } from '../types';
import { useCollapse } from 'react-collapsed';
import { ChevronUpIcon } from '../../../ui/icons/ChevronUp';
import { ChevronDownIcon } from '../../../ui/icons/ChevronDown';
import { TruncatedText } from '../../../ui/TruncatedText';
import { Events } from '../Events';
import { ActionStatus, TaskStatus } from '../../../../gql/graphql';
import { ProgressCircle } from '../../../ui/ProgressCircle';
import { filterNotNull } from '../../../../utils/types/filterNotNull';
import { AlertTriangleIcon } from '../../../ui/icons/AlertTriangle';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../../../apiHooks/useCompany';

interface Props {
  index: number;
  stage: ProjectStage;
  isFuture: boolean;
  isExpanded: boolean;
  onClick: (id: number) => void;
}

export const Stage = ({
  index,
  stage,
  isFuture,
  isExpanded,
  onClick,
}: Props) => {
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');
  const isPastDueUncompletedEventsVisible =
    company?.settings?.pastDueUncompletedEventsVisible;

  const visits = useMemo(
    () =>
      filterNotNull(stage.tasks).flatMap((task) =>
        task.taskVisits
          .filter(
            (visit) =>
              isPastDueUncompletedEventsVisible ||
              visit.isCompleted ||
              new Date(visit.endDate) >= new Date(),
          )
          .map((visit, index) => ({
            ...visit,
            task,
            order: index + 1,
            totalVisits: task.taskVisits.length,
          })),
      ),
    [stage.tasks, isPastDueUncompletedEventsVisible],
  );

  const isExpandable = Boolean(
    stage.description || stage.actions.length > 0 || visits.length > 0,
  );

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const handleClick = useCallback(() => {
    if (!isExpandable) {
      return;
    }

    onClick(stage.id);
  }, [onClick, stage.id, isExpandable]);

  const notCompletedHomeOwnerTasks = useMemo(
    () =>
      stage.actions.filter(
        (action) => action.status === ActionStatus.Requested,
      ),
    [stage.actions],
  );

  const notCompletedTasks = useMemo(
    () =>
      stage.tasks.filter(
        (task) =>
          task &&
          task.status !== TaskStatus.Completed &&
          task.status !== TaskStatus.Cancelled,
      ),
    [stage.tasks],
  );

  const isCompleted = stage.status === ProjectStageStatus.Completed;

  const completedTasksCount = stage.tasks.length - notCompletedTasks.length;
  const completedHomeOwnerTasksCount =
    stage.actions.length - notCompletedHomeOwnerTasks.length;

  return (
    <StageContainer isFuture={isFuture}>
      <StageHeader
        title={stage.name}
        isExpandable={isExpandable}
        {...getToggleProps({ onClick: handleClick })}
      >
        {isCompleted && <CheckCircleIcon color="#009A47" size="24px" />}
        {!isCompleted && (
          <ProgressCircle
            size={24}
            progress={
              ((completedTasksCount + completedHomeOwnerTasksCount) /
                (stage.actions.length + stage.tasks.length)) *
              100
            }
            color="#235DFF"
          />
        )}
        <StageName>
          {index + 1}. {stage.name}
        </StageName>
        {isExpandable && (
          <>
            {isExpanded ? (
              <ChevronUpIcon size="24px" color="#9C9CAA" />
            ) : (
              <ChevronDownIcon size="24px" color="#9C9CAA" />
            )}
          </>
        )}

        {!isExpanded && notCompletedHomeOwnerTasks.length > 0 && (
          <ActionRequired>
            <AlertTriangleIcon size="16px" color="#FFBD13" />
            <span>Action required</span>
          </ActionRequired>
        )}

        {isCompleted && (
          <Badge color="#009A47" bgColor="#CDF3DF">
            COMPLETED
          </Badge>
        )}
        {stage.status === ProjectStageStatus.InProgress && (
          <Badge color="#4D7CFE" bgColor="#DBE5FF">
            IN PROGRESS
          </Badge>
        )}
      </StageHeader>
      <StageContent {...getCollapseProps()}>
        <div>
          <StageDescription>
            {stage.description && (
              <TruncatedText text={stage.description} maxChars={200} />
            )}
          </StageDescription>

          <Events actions={stage.actions} visits={visits} />
        </div>
      </StageContent>
    </StageContainer>
  );
};
