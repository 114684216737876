import React, { useMemo } from 'react';
import {
  List,
  PlaceholderPrimary,
  PlaceholderSecondary,
  Title,
} from './styled';
import { Panel } from '../../kit/ui/Panel';
import { useServiceVisits } from '../../apiHooks/useServiceVisits';
import { Visit } from '../../kit/components/Visit';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../apiHooks/useCompany';

interface Props {
  hideIfEmpty?: boolean;
  isCompletedShown?: boolean;
}

export const Services = ({
  hideIfEmpty = false,
  isCompletedShown = false,
}: Props) => {
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');
  const isPastDueUncompletedEventsVisible =
    company?.settings?.pastDueUncompletedEventsVisible;

  const { data: visits = [], isLoading } = useServiceVisits();

  const filteredVisits = useMemo(() => {
    const result = visits.filter((visit) => {
      if (!isCompletedShown && visit.isCompleted) {
        return false;
      }

      if (visit.isCompleted) {
        return true;
      }

      return (
        isPastDueUncompletedEventsVisible ||
        new Date(visit.endDate) >= new Date()
      );
    });

    result.sort((a, b) => {
      const aDate = a.startDate;
      const bDate = b.startDate;

      const aCompleted = a.isCompleted;
      const bCompleted = b.isCompleted;

      if (aCompleted && bCompleted) {
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      if (aCompleted) {
        return 1;
      }

      if (bCompleted) {
        return -1;
      }

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    return result;
  }, [visits, isPastDueUncompletedEventsVisible, isCompletedShown]);

  if (isLoading) {
    return null;
  }

  if (hideIfEmpty && filteredVisits.length === 0) {
    return null;
  }

  return (
    <Panel>
      <Title>Service Visits ({filteredVisits.length})</Title>

      {filteredVisits.length === 0 && (
        <div>
          <PlaceholderPrimary>You have no services yet</PlaceholderPrimary>
          <PlaceholderSecondary>
            We look forward to work with you!
          </PlaceholderSecondary>
        </div>
      )}
      {filteredVisits.length > 0 && (
        <List>
          {filteredVisits.map((visit) => (
            <Visit key={visit.id} visit={visit} />
          ))}
        </List>
      )}
    </Panel>
  );
};
