import styled from 'styled-components';

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ImageContainer = styled.div<{ width?: number; height?: number }>`
  height: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  position: relative;
  border-radius: ${({ width }) => (width && width >= 60 ? 8 : 4)}px;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: ${({ width }) => width}px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  ${Image}, img {
    height: 100%;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    border-radius: ${({ width }) => (width && width >= 60 ? 8 : 4)}px;
`;
