import React from 'react';
import { Container, Right, Title, TwoBadges, When } from './styled';
import { CalendarIcon } from '../../ui/icons/Calendar';
import { UserCard } from './UserCard';
import { Badge } from '../../ui/Badge';
import { BadgeSize } from '../../ui/Badge/types';
import { CheckCircleIcon } from '../../ui/icons/CheckCircle';
import { ToolIcon } from '../../ui/icons/Tool';

export type TaskVisit = {
  id: number;
  startDate: string;
  endDate: string;
  isCompleted: boolean | null;
  task: {
    title: string;
    assignee: {
      firstName: string | null;
      lastName: string | null;
      avatarUrl: string | null;
    } | null;
  } | null;
};

const formatDate = (startDate: Date, endDate?: Date) => {
  if (endDate) {
    return `${new Date(startDate).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    })} - ${new Date(endDate).toLocaleString('en-US', {
      timeStyle: 'short',
    })}`;
  }

  return new Date(startDate).toLocaleString('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  });
};

const getVisitStatus = (visit: TaskVisit) => {
  if (visit.isCompleted) {
    return {
      color: '#009A47',
      bgColor: '#CDF3DF',
      text: 'Visit completed',
    };
  }

  if (!visit.task?.assignee) {
    return {
      color: '#778CA2',
      bgColor: '#E4E8EC',
      text: 'Scheduled',
    };
  }

  return {
    color: '#F1AA12',
    bgColor: '#FCF2DB',
    text: 'Confirmed',
  };
};

interface Props {
  visit: TaskVisit;
  order?: number;
}

export const Visit = ({ visit, order }: Props) => {
  const status = getVisitStatus(visit);

  const isToday =
    new Date(visit.startDate).toDateString() === new Date().toDateString();

  const isDimmed =
    visit.isCompleted || (!isToday && new Date(visit.startDate) < new Date());

  return (
    <Container isHighlighted={isToday} isDimmed={isDimmed}>
      <div>
        <Title>
          {!visit.isCompleted && <ToolIcon size="16px" color="#9C9CAA" />}
          {visit.isCompleted && <CheckCircleIcon size="16px" color="#71E7A9" />}

          {visit.task?.title ?? 'No title'}
        </Title>
        <When>
          <CalendarIcon size="16px" color={isToday ? '#FFBD13' : '#9C9CAA'} />
          {formatDate(new Date(visit.startDate), new Date(visit.endDate))}
        </When>
      </div>

      <Right>
        <TwoBadges>
          {Boolean(order) && (
            <Badge size={BadgeSize.Small} color="#828D9A" bgColor="white">
              Visit {order}
            </Badge>
          )}
          <Badge
            size={BadgeSize.Small}
            color={status.color}
            bgColor={status.bgColor}
          >
            {status.text.toUpperCase()}
          </Badge>
        </TwoBadges>
        {visit.task?.assignee && (
          <UserCard
            isDimmed={isDimmed}
            avatarSize={20}
            user={visit.task.assignee}
          />
        )}
      </Right>
    </Container>
  );
};
